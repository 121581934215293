import { DocumentType, GroupType } from '@innedit/innedit-type';
import { GroupData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
import IconEye from '~/icons/Eye';
import IconEyeSlash from '~/icons/EyeSlash';
import IconMinusSquare from '~/icons/MinusSquare';
import IconPlus from '~/icons/Plus';
import IconPlusSquare from '~/icons/PlusSquare';
import IconSquare from '~/icons/Square';
import { colors } from '~/styles/theme';

import ListItem from './index';

const ListItemGroup: FC<ListItemProps<GroupType, GroupData>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [doc, setDoc] = useState<DocumentType<GroupType>>();
  const [docs, setDocs] = useState<DocumentType<GroupType>[]>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  useEffect(() => {
    let isMounted = true;
    if (isOpen && doc && doc.nbChildren > 0) {
      const subModel = new GroupData({
        espaceId: model.espaceId,
      });
      subModel
        .find({
          wheres: {
            parent: docId,
          },
        })
        .then(newDocs => {
          if (isMounted) {
            setDocs(newDocs);
          }

          return true;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [doc, isOpen]);

  if (!doc) {
    return null;
  }

  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault();

    setIsOpen(io => {
      if (io) {
        setDocs(undefined);
      }

      return !io;
    });
  };

  return (
    <ListItem
      actions={[
        {
          icon: doc.hidden ? IconEyeSlash : IconEye,
          label: 'Changer la visibilité',
        },
        {
          icon: IconPlus,
          label: 'Ajouter un sous-groupe',
          to: `/espaces/${doc.espaceId}/contacts/groups/create?parent=${docId}`,
        },
      ]}
      after={
        docs &&
        docs.length > 0 && (
          <div className="bg-light-400 pl-6">
            {docs.map(d => (
              <ListItemGroup
                key={d.id}
                docId={d.id}
                model={model}
                onClick={onClick}
              />
            ))}
          </div>
        )
      }
      className={className}
      displayActionId={false}
      doc={doc}
      index={index}
      label={doc.label ?? doc.id}
      onClick={onClick}
    >
      <div className="flex min-h-10.5 items-center space-x-3">
        {doc.nbChildren > 0 ? (
          <>
            {isOpen ? (
              <IconPlusSquare
                color={colors.light[700]}
                onClick={handleOnClick}
              />
            ) : (
              <IconMinusSquare
                color={colors.light[700]}
                onClick={handleOnClick}
              />
            )}
          </>
        ) : (
          <IconSquare color={colors.light[700]} />
        )}
        <div className="flex flex-1 flex-col">
          <strong>{doc.label}</strong>
        </div>
      </div>
    </ListItem>
  );
};

export default ListItemGroup;
